<template>
    <div>
        <v-data-table
        :headers="headers"
        :items="anbieter">
            <template v-slot:item.actions="{ item }">
                <v-icon @click="anbieterEdit(item.id)">mdi-pencil</v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    export default {
        name: "AnbieterTable",
        computed: {
            anbieter: {
                get() {
                    return this.$store.$db().model('anbieter').query().all()
                }
            }
        },
        data () {
            return {
                headers: [
                    {text: 'Name', value: 'name'},
                    {text: 'Adresse', value: 'address'},
                    {text: 'Logo', value: 'logo'},
                    { text: 'Actions', value: 'actions', sortable: false },
                ]
            }
        },
        methods: {
            anbieterEdit (id) {
                this.$router.push({path: `/edit-anbieter/${id}`})
            }
        }
    }
</script>

<style scoped>

</style>
