<template>
    <v-container>
        <h1 align="center">Anbieter</h1>
        <AnbieterTable></AnbieterTable>
        <v-btn to="/new-anbieter/" block color="primary">Neuer Anbieter</v-btn>
    </v-container>
</template>

<script>
    import AnbieterTable from '@/components/AnbieterTable';
    export default {
        name: "Anbieter",
        components: {AnbieterTable},
    }
</script>

<style scoped>

</style>
